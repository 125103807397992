import React, { useContext, useMemo } from "react";
import "./index.css"
import { ModuloType } from "../../../types/ModulosTypes";
import ModuloIcone from "../../ModuloIcone";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { DommusContext } from "../../../contexts/DommusContext";
import { trackPromise } from "react-promise-tracker";
import { GuService } from "../../../services/GuService";
import Swal from "sweetalert2";
import { ModulosEnum } from "../../../types/ModulosEnum";

interface CardModuloProps {
    modulo: ModuloType;
}

export function CardModulo({ modulo }: CardModuloProps) {
    const { guToken, currentModule } = useContext(DommusContext);
    
    const handleAcessarModulo = () => {
        trackPromise(GuService.acessarModulo(guToken, modulo.id)).then((response) => {
            if(currentModule.id === ModulosEnum.VENDAS){
                window.open(`${response?.data?.modulo?.uri}:${guToken}`, "_self");
            }else{
                window.open(`${response.data.modulo.uri}${response.data.token}:${guToken}`, "_self");
            }
        }).catch((error) => {
            Swal.fire({
                titleText: "Não permitido",
                text: error?.response?.data?.message ?? error,
                icon: "error",
            });
        })
    }

    const isAtivo = useMemo(() => {
        return modulo.id !== currentModule.id;
    }, [modulo.id, currentModule])

    return (
        <Col className="mb-4" sm={12} md={6} lg={6}>
            <div className="card-modulo" onClick={() => {
                if (isAtivo) {
                    handleAcessarModulo();
                }
            }}>
                <div className="icon-section">
                    <ModuloIcone sigla={modulo.sigla} ativo={isAtivo} />
                </div>
                <div className="conteudo-section">
                    <div className="info-section">
                        <span className="title">{modulo?.nome}</span>
                        <span className="description">{modulo?.descricao}</span>
                    </div>
                    <div className="acessar-section">
                        <span>Acessar</span>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                </div>
                {!isAtivo &&
                    <div className="indicador-modulo-atual">
                        <svg xmlns="http://www.w3.org/2000/svg" width="256" height="256" viewBox="0 0 256 256"><path fill="currentColor" d="M225.86 102.82c-3.77-3.94-7.67-8-9.14-11.57c-1.36-3.27-1.44-8.69-1.52-13.94c-.15-9.76-.31-20.82-8-28.51s-18.75-7.85-28.51-8c-5.25-.08-10.67-.16-13.94-1.52c-3.56-1.47-7.63-5.37-11.57-9.14C146.28 23.51 138.44 16 128 16s-18.27 7.51-25.18 14.14c-3.94 3.77-8 7.67-11.57 9.14c-3.25 1.36-8.69 1.44-13.94 1.52c-9.76.15-20.82.31-28.51 8s-7.8 18.75-8 28.51c-.08 5.25-.16 10.67-1.52 13.94c-1.47 3.56-5.37 7.63-9.14 11.57C23.51 109.72 16 117.56 16 128s7.51 18.27 14.14 25.18c3.77 3.94 7.67 8 9.14 11.57c1.36 3.27 1.44 8.69 1.52 13.94c.15 9.76.31 20.82 8 28.51s18.75 7.85 28.51 8c5.25.08 10.67.16 13.94 1.52c3.56 1.47 7.63 5.37 11.57 9.14c6.9 6.63 14.74 14.14 25.18 14.14s18.27-7.51 25.18-14.14c3.94-3.77 8-7.67 11.57-9.14c3.27-1.36 8.69-1.44 13.94-1.52c9.76-.15 20.82-.31 28.51-8s7.85-18.75 8-28.51c.08-5.25.16-10.67 1.52-13.94c1.47-3.56 5.37-7.63 9.14-11.57c6.63-6.9 14.14-14.74 14.14-25.18s-7.51-18.27-14.14-25.18Zm-52.2 6.84l-56 56a8 8 0 0 1-11.32 0l-24-24a8 8 0 0 1 11.32-11.32L112 148.69l50.34-50.35a8 8 0 0 1 11.32 11.32Z" /></svg>
                    </div>
                }
            </div>
        </Col>
    )
}